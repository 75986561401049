const ResponsiveTable = (function(){

  let tables = [];
  let isMobile = false;

  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    const tableElements = document.querySelectorAll('.table-responsive');
    tables = Array.from(tableElements);
    handleMediaQuery()

    tables.forEach(table => {
      const trigger = document.querySelector(table.dataset.toggler);
      if (trigger) {
        trigger.addEventListener('click', event => {
          if (table.dataset.display == 'list') {
            notifyPhone(table)
          } else if (!isMobile) {
            notifyNotPhone(table);
          }
        });
      }
    });
  }

  function handleMediaQuery() {
    const mq = window.matchMedia( "(max-width: 650px)" );
    if (mq.matches) {
      isMobile = true;
      notifyPhone(null)
    } else {
      isMobile = false;
      notifyNotPhone(null)
    }

    mq.addListener(function(changed) {
      if(changed.matches) {
        isMobile = true;
        notifyPhone(null)
      } else {
        isMobile = false;
        notifyNotPhone(null)
      }
    });
  }

  function notifyPhone(optionalTable){
    const filteredTables = tables.filter(t=> {
      return optionalTable ? t == optionalTable : true
    });

    filteredTables.forEach(table => {
      handleTable(table, true);
    });
  }

  function notifyNotPhone(optionalTable) {
    const filteredTables = tables.filter(t=> {
      return optionalTable ? t == optionalTable : true
    });

    filteredTables.forEach(table => {
      handleTable(table, false);
    });
  }

  function handleTable(table, boxDisplay) {
    const columns = createColumns(table);
    toggleTriggerDisplay(table, boxDisplay);

    if (boxDisplay) {
      table.classList.add('table-box');
      if (table.dataset.container) {
        document.querySelector(table.dataset.container).classList.add('table-box-container')
      }
      table.dataset.display = 'box'
      columns.forEach(column => {
        column.cells.forEach(cell => {
          createLabel(cell, column.header);
        })
      });
    } else {
      table.classList.remove('table-box');
      if (table.dataset.container) {
        document.querySelector(table.dataset.container).classList.remove('table-box-container')
      }
      table.dataset.display = 'list'
      columns.forEach(column => {
        column.cells.forEach(cell =>{
          removeLabel(cell);
        })
      });
    }
  }

  function toggleTriggerDisplay(table, boxDisplay){
    const trigger = document.querySelector(table.dataset.toggler);
    if (trigger) {
      const icon = trigger.querySelector('.fas')
      if (boxDisplay) { icon.classList.remove(table.dataset.listIcon); icon.classList.add(table.dataset.boxIcon); }
      else { icon.classList.remove(table.dataset.boxIcon); icon.classList.add(table.dataset.listIcon);}
    }
  }

  function createColumns(table){
    let rowIndex = 0;

    const rows = Array.from(table.querySelectorAll('tr'));
    let colums = [];

    rows.forEach(row => {
      if (rowIndex == 0) {
        const cells = Array.from(row.querySelectorAll('th'));
        cells.forEach(cell => {
          colums.push({
            header: cell.innerText,
            cells: []
          });
        });
      } else {
        const cells = Array.from(row.querySelectorAll('td'));
        let cellIndex = 0;
        cells.forEach(cell => {
          colums[cellIndex].cells.push(cell);
          cellIndex++
        });
      }
      rowIndex++;
    });
    return colums;
  }

  function createLabel(cell, text){
    if (text == "" ) { return; }
    if (cell.dataset.noLabel) {return; }
    const label = document.createElement('div');
    label.classList.add('table-label');
    label.innerText = text;
    label.innerHTML = label.innerHTML.split("<br>").join("")
    cell.insertBefore(label, cell.firstChild)
  }

  function removeLabel(cell){
    const label = cell.querySelector('.table-label');
    if (label) { cell.removeChild(label); }
  }

  return {
    init: init
  }
})();

export default ResponsiveTable;
