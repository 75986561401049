const Navbar = (function(){

  const sticky = 390;

  function init(){
    const navbar = document.querySelector(".navbar-sticky");
    const mq = window.matchMedia( "(max-width: 800px)" );
    if (!mq.matches) {
      window.onscroll = function () {
        toggleSticky(navbar);
      }
      window.setTimeout(_ => {
        navbar.classList.remove('is-hidden')
        toggleSticky(navbar)
      }, 100);
    }
  }

  function toggleSticky(navbar) {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add("navbar-sticky--active")
    } else {
      navbar.classList.remove("navbar-sticky--active");
    }
  }

  return {
    init: init
  }
})();

export default Navbar
