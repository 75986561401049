const RemoteForms = (function(){

  let targetElementId = null;

  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    const asyncContainers = document.querySelectorAll('[data-remote]');
    if (asyncContainers == null || asyncContainers.length === 0) { return; }

    Array.from(asyncContainers).forEach((asyncContainer) => {
      handleAsyncContainer(asyncContainer);
    })
  }

  function handleAsyncContainer(element) {
    targetElementId = element.id
    initializeAjaxCallback(element);
  }

  function initializeAjaxCallback(formElement){
    formElement.removeEventListener('ajax:success', onAjaxSuccess);
    formElement.addEventListener('ajax:success', onAjaxSuccess);

    formElement.removeEventListener('ajax:complete', onAjaxComplete)
    formElement.addEventListener('ajax:complete', onAjaxComplete)
  }

  function onAjaxSuccess(event) {
    if (event.detail[2].status === 200) {
      const xhr = event.detail[2];
      const stringData = xhr.response;
      if (stringData.includes("Turbolinks")) {
        return;
      }

      if (!xhr.getResponseHeader('Content-Type').includes('application/json')) {
        document.getElementById(targetElementId).innerHTML = stringData;
      }

      var scripts = document.getElementById(targetElementId).getElementsByTagName("script");
      for (var i = 0; i < scripts.length; ++i) {
        var script = scripts[i];
        eval(script.innerHTML);
      }

      fireAjaxRefreshEvent();
    }
  }

  function onAjaxComplete(event){
    const responseCode = event.detail[0].status

    if (responseCode === 302 || responseCode === 301) {
      let redirect = event.detail[0].getResponseHeader('X-Ajax-Redirect-Url');

      if (window.location.href.replace('#', '') == window.location.origin + redirect) {
        location.reload();
      } else {
        window.location.href = window.location.origin + redirect;
      }
    }
  }

  function fireAjaxRefreshEvent() {
    const event = new CustomEvent('ajax::refresh', {detail: {}});
    document.dispatchEvent(event);
  }

  return {
    init: init
  }
})();

export default RemoteForms;
