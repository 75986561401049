// .carousel{data: {carousel: 'true'}}
//   .carousel-items
//     .item{data: {carousel-item: 'true'}}
//     .item{data: {carousel-item: 'true'}}
//   .arrow-left
//     <-
//   .arrow-right
//     ->

const Carousel = (function(){
  let index = 0;
  let elementsCount = 0;
  let container;
  let elementIndicators;
  let left;
  let right;
  let stopAutomatedAnimation = false;

  function init() {
    const carousels = document.querySelectorAll('[data-carousel]');
    Array.from(carousels).forEach((carousel) => {
      handleCarousel(carousel)
    })
  }

  function handleCarousel(element){
    const carousel = element;

    elementsCount = carousel.querySelectorAll('[data-carousel-item]').length - 1;

    elementIndicators = Array.from(carousel.querySelectorAll('[data-carousel-indicator]'));
    left = carousel.querySelector('.arrow-left');
    right = carousel.querySelector('.arrow-right');
    container = carousel.querySelector('.carousel-items');

    if (left) {
      left.addEventListener('click', function () {
        onLeftClicked();
      });
    }

    if (right) {
      right.addEventListener('click', function () {
        onRightClicked();
      });
    }
    handleItemNotifications();
    nextItem();
  }

  function onRightClicked(){
    if (index == elementsCount) {
      return;
    }

    stopAutomatedAnimation = true;
    index += 1;
    setItem(index);
  }

  function onLeftClicked(){
    if (index == 0) {
      return;
    }

    stopAutomatedAnimation = true;
    index -= 1;
    setItem(index);
  }

  function nextItem() {
    window.setTimeout(function() {
      setItem(index)
      index += 1;
      if (index > elementsCount) {
        index = 0;
      }
      if (!stopAutomatedAnimation) {
        nextItem();
      }
    }, 10000);
  }

  function setItem(index) {
    if (left) { left.style.opacity = '1'; }
    if (right) { right.style.opacity = '1'; }
    container.style.transform = 'translateX(' + index * -100 + '%)'

    if (index == 0 && left) {
      left.style.opacity = '0.25';
    }

    if (index == elementsCount && right) {
      right.style.opacity = '0.25';
    }

    showItemNotification(index);
  }

  function handleItemNotifications() {
    elementIndicators.forEach(element => {
      element.addEventListener('click', (event) => {
        stopAutomatedAnimation = true;
        setItem(event.currentTarget.dataset.slideTo);
      })
    })
  }

  function showItemNotification(index){
    elementIndicators.forEach(element => {
      element.classList.remove('active');
    })

    elementIndicators[index].classList.add('active')
  }

  return {
    init: init
  }
})();

export default Carousel;
