import Form from './form'
import ResponsiveTable from "./responsive-table";
import RemoteForms from "./remote-form";
import LanguageSwitch from "./language_switch";
import {Slider} from "./slider";
import {Expander} from "./dialog";

import Navbar from './navbar'
import Carousel from'./carousel'

document.addEventListener("DOMContentLoaded", function() {
  Form.init();
  Expander.init();
  ResponsiveTable.init();
  RemoteForms.init()
  LanguageSwitch.init();
  Navbar.init();
  Carousel.init();
});
