const Form = (function(){
  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    handleErrors();
    hookUpSubmitButtons();
    hookUpToggleListeners();
  }

  function handleErrors() {
    const errorElements = document.querySelectorAll('.error-notification');
    if (errorElements == null || errorElements.length == 0) { return; }

    Array.from(errorElements).forEach(errorElement => {
      errorElement.parentElement.classList.add('field_with_errors')
    })
  }

  function hookUpSubmitButtons() {
    const submitElements = document.querySelectorAll('[data-submit]');
    Array.from(submitElements).forEach(submitElement => {
      submitElement.removeEventListener('click', onSubmit)
      submitElement.addEventListener('click', onSubmit)
    })
  }

  function onSubmit(event) {
    document.querySelector(event.currentTarget.dataset.submit).submit()
  }

  function hookUpToggleListeners() {
    const hiddenVisiblePasswordTogglers = document.querySelectorAll('[data-toggle-password]')
    Array.from(hiddenVisiblePasswordTogglers).forEach(element => {
      element.removeEventListener('click', onToggle)
      element.addEventListener('click', onToggle)
    })
  }

  function onToggle(event) {
    const hiddenVisiblePasswordToggler = event.currentTarget;
    const parent = hiddenVisiblePasswordToggler.parentElement.parentElement;
    const target = parent.querySelector('input')

    if (target.type == 'password') {
      target.type = 'text';
      if (hiddenVisiblePasswordToggler.dataset.activeClass) {
        hiddenVisiblePasswordToggler.classList.add(hiddenVisiblePasswordToggler.dataset.activeClass)
      }
    } else {
      if (hiddenVisiblePasswordToggler.dataset.activeClass) {
        hiddenVisiblePasswordToggler.classList.remove(hiddenVisiblePasswordToggler.dataset.activeClass)
      }
      target.type = 'password'
    }
  }

  return {
    init: init
  }
})();

export default Form;
